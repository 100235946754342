var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "PayoutsView" },
    [
      _c(
        "ui-card",
        {
          attrs: {
            header: "Payouts",
            description: "Payouts for the current operator",
          },
        },
        [
          _c("MuiAlgoliaList", {
            attrs: {
              "table-config": _vm.algolia.fields,
              "export-columns": _vm.algolia.fields,
              facets: _vm.algolia.facets,
              filters: `cs_operator.uuid:${_vm.activeOperator.uuid}`,
              index: _vm.ALGOLIA_INDEXES.payoutTransactions,
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }