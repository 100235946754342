<script>
import assign from 'lodash/assign';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import { mapActions, mapGetters, mapState } from 'vuex';
import { BUTTON_TYPES } from '@emobg/vue-base';
import { MuiAlgoliaSelect } from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { displayBookingSelector } from '@domains/Invoicing/Invoices/helpers/invoices.helpers';

import { CancelButton, GenericModalComponent } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'InvoiceFormComponent',

  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
  },

  inject: ['$labels'],
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isForCompany: false,
      isLoading: false,
      inputs: {
        user_uuid: null,
        company_uuid: null,
        booking_uuid: null,
      },
    };
  },

  computed: {
    filterBookingByCompanyUser() {
      let filter = this.getOperatorFilter();

      if (!isNil(this.inputs.company_uuid) || !isNil(this.inputs.user_uuid)) {
        const target = (this.isForCompany) ? 'company_uuid' : 'user_uuid';
        const value = (this.isForCompany) ? this.inputs.company_uuid : this.inputs.user_uuid;

        filter += ` AND ${target}: ${value}`;
      }

      return filter;
    },
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      invoiceStatus: state => state.STATUS,
      invoiceData: state => state.data,
      invoiceErrors: state => state.error,
    }),

    areAllRequiredFieldsFilled() {
      return this.inputs.user_uuid || this.inputs.company_uuid;
    },
  },

  watch: {
    isForCompany(val) {
      if (val) {
        this.inputs.user_uuid = null;
      } else {
        this.inputs.company_uuid = null;
      }
    },
    'inputs.user_uuid': value => {
      const initialUserUuid = get(this, 'initialData.user_uuid');
      const initialBookingUuid = get(this, 'initialData.booking_uuid');
      if (initialUserUuid === value && initialBookingUuid === this.inputs.booking_uuid) {
        return;
      }
      set(this, 'inputs.booking_uuid', null);
    },
    'inputs.company_uuid': () => {
      set(this, 'inputs.booking_uuid', null);
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.displayBookingSelector = displayBookingSelector;
    if (!isEmpty(this.initialData)) {
      assign(this.inputs, this.initialData);
    }
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, [
      'postInvoice',
    ]),
    async createInvoice() {
      this.isLoading = true;

      const data = assign({ cs_operator_uuid: this.activeOperator.uuid }, this.inputs);
      await this.postInvoice(data);

      if (this.invoiceStatus.LOADED) {
        window.open(`/invoicing/invoices/${this.invoiceData.uuid}/summary/details`, '_blank');
        this.closeModal();
      } else if (this.invoiceStatus.ERROR) {
        this.isLoading = false;
        this.$throwError(this.invoiceErrors);
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    setIdFromEntity(attribute, result) {
      this[attribute] = result ? result.id : null;
    },
  },
};
</script>

<template>
  <ui-form @submitform="createInvoice">
    <GenericModalComponent
      v-bind="$attrs"
      :size="SIZES.small"
      title="Create invoice"
    >
      <template #body>
        <div
          class="InvoiceFormComponent"
          data-test-id="invoice-modal"
        >
          <div class="row">
            <div class="col-12">
              <label class="emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1">
                Customer Type*
              </label>
              <ui-radio
                :value="isForCompany"
                :option="false"
                required
                class="my-2 w-100"
                name="is_for_company"
                caption="User"
                data-test-id="invoice-modal-customer-user-select"
                @changevalue="() => isForCompany = false"
              />
              <ui-radio
                :value="isForCompany"
                :option="true"
                required
                class="mb-2 mt-1 w-100"
                name="is_for_company"
                caption="Company"
                data-test-id="invoice-modal-customer-company-select"
                @changevalue="() => isForCompany = true"
              />
            </div>
            <div class="col-12 mt-3 mb-4">
              <MuiAlgoliaSelect
                v-if="isForCompany"
                key="companySelector"
                v-model="inputs.company_uuid"
                :filters="getOperatorFilter({ attribute: 'cs_operator_id' })"
                :title="(result) => `${result.id} - ${result.commercial_name}`"
                :index="ALGOLIA_INDEXES.companies"
                class="w-100"
                label="Company*"
                name="company"
                no-cache
                path-value="uuid"
                placeholder="Select company"
                data-test-id="invoice-modal-company-autocomplete"
              />
              <MuiAlgoliaSelect
                v-else
                key="userSelector"
                v-model="inputs.user_uuid"
                :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.users })"
                :title="(result) => `${result.first_name} ${result.last_name} - ${result.email}`"
                :index="ALGOLIA_INDEXES.users"
                class="w-100"
                label="User*"
                name="user"
                no-cache
                path-value="uuid"
                placeholder="Select user"
                data-test-id="invoice-modal-user-autocomplete"
                @selected="(result) => setIdFromEntity('user_fk', result)"
              />
            </div>
            <div class="col-12">
              <MuiAlgoliaSelect
                v-model="inputs.booking_uuid"
                :filters="filterBookingByCompanyUser"
                :title="(result) => displayBookingSelector(result)"
                :index="ALGOLIA_INDEXES.csBookings"
                class="w-100"
                label="Booking"
                name="cs_booking"
                no-cache
                path-value="uuid"
                placeholder="Select booking"
                data-test-id="invoice-modal-booking-autocomplete"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="d-flex align-content-center">
          <CancelButton
            data-test-id="invoice-modal-cancel"
            @click="closeModal"
          />
          <ui-button
            :disabled="!areAllRequiredFieldsFilled"
            :loading="isLoading"
            :type="BUTTON_TYPES.submit"
            data-test-id="invoice-modal-save-button"
          >
            {{ $labels.Common.Actions.save }}
          </ui-button>
        </div>
      </template>
    </GenericModalComponent>
  </ui-form>
</template>
