import get from 'lodash/get';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  TIME_ZONE,
  formatUtc,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import CRM_ROUTES from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';

export const getFields = () => [
  {
    title: 'User',
    attributeName: 'user.id',
    displayPriority: 1,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: CRM_ROUTES.users.detail.index,
        params: { userUuid: result.user.uuid },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
      text: `#${result.user.id}`,
    }),
  },
  {
    title: 'User Name',
    attributeName: 'user',
    displayPriority: 2,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: CRM_ROUTES.users.detail.index,
        params: { userUuid: result.user.uuid },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
      text: `${result.user.first_name} ${result.user.last_name}`,
    }),
  },
  {
    attributeName: 'booking.city',
    title: 'City',
    displayPriority: 1,
  },
  {
    attributeName: 'date_ts',
    title: 'Date',
    displayPriority: 1,
    transformValue: value => formatUtc(value, DATE_FORMAT.dob),
  },
  {
    attributeName: 'amount_formatted',
    title: 'Amount',
    displayPriority: 1,
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 1,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'type',
    title: 'Type',
    displayPriority: 1,
  },
  {
    title: 'Booking ID',
    attributeName: 'booking.id',
    displayPriority: 1,
    type: RECORD_TYPES.link,
    linkClass: 'emobg-link-primary emobg-body-2',
    transformValue: bookingId => `#${bookingId}`,
    url: ({ booking }) => `/carsharing/bookings/${booking.uuid}`,
  },
  {
    title: 'Case ID',
    attributeName: 'case.id',
    displayPriority: 2,
    type: RECORD_TYPES.link,
    transformValue: caseId => (caseId && `#${caseId}`),
    url: result => {
      const caseUuid = get(result, 'case.uuid');
      return caseUuid ? `/cases-service/case-events/${caseUuid}` : FALLBACK_MESSAGE.dash;
    },
  },
  {
    attributeName: 'iban',
    title: 'IBAN ends in',
    displayPriority: 2,
  },
  {
    title: 'Agent Name',
    attributeName: 'submitted_by',
    transformValue: submittedBy => `${submittedBy.first_name} ${submittedBy.last_name}`,
    displayPriority: 2,
  },
];

export const getFacets = operatorTimezone => [
  {
    type: 'rangedatetimepicker',
    props: {
      title: 'Date',
      attributeName: 'date_ts',
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },
      timezone: operatorTimezone || TIME_ZONE.default,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'booking.city',
      limit: 5,
      transformValue: value => value || 'Reindexing',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Type',
      attributeName: 'type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'rangeslider',
    props: {
      title: 'Amount',
      attributeName: 'amount',
    },
  },
];
