var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "InvoiceListView" },
    [
      _c("h1", { staticClass: "mb-3" }, [_vm._v(" Invoices ")]),
      _c("MuiAlgoliaList", {
        attrs: {
          "table-config": _vm.contentCells,
          filters: _vm.invoiceFilters(),
          facets: _vm.invoiceFacets,
          "export-columns": _vm.exportColumns,
          actions: _vm.actions,
          index: _vm.ALGOLIA_INDEXES.invoices,
          "is-export-enabled": "",
          "data-test-id": "invoice-list",
        },
      }),
      _vm.isSapExportModalOpen
        ? _c("SapExportFormComponent", {
            attrs: { title: "SAP Export" },
            on: {
              closeModal: function ($event) {
                _vm.isSapExportModalOpen = false
              },
            },
          })
        : _vm._e(),
      _vm.isInvoiceModalOpen
        ? _c("InvoiceFormComponent", {
            attrs: { title: "Create invoice" },
            on: {
              closeModal: function ($event) {
                _vm.isInvoiceModalOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }