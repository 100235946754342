var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b(
      {
        attrs: { size: _vm.SIZES.small, "is-open": "" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "row p-5" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.isReportsLoaded
                        ? _c("ui-select", {
                            staticClass: "w-100 mb-3",
                            attrs: {
                              value: _vm.inputs.report,
                              label: _vm.$labels.Payments.select_report,
                              name: "reportSelector",
                              "data-test-id": "conciliation-modal-selector",
                            },
                            domProps: {
                              options: _vm.reportsOptions,
                              searchbox: { threshold: -1 },
                            },
                            on: {
                              selectoption: ({ detail }) =>
                                (_vm.inputs.report = detail),
                            },
                          })
                        : _c("ui-skeleton", { attrs: { height: "40" } }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-content-center" },
                  [
                    _c("CancelButton", {
                      attrs: {
                        disabled: _vm.isReportMediaLoading,
                        "data-test-id": "conciliation-modal-cancel-button",
                      },
                      on: { click: _vm.closeModal },
                    }),
                    _c(
                      "ui-button",
                      {
                        attrs: {
                          disabled: !_vm.inputs.report,
                          loading: _vm.isReportMediaLoading,
                          "data-test-id": "conciliation-modal-export-button",
                        },
                        on: { clickbutton: _vm.exportReport },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$labels.Common.Actions.export) + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "GenericModalComponent",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }