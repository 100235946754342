<script>
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import ConciliationReportModal from './modals/ConciliationReportModal';
import { contentFields, facets } from '../const/paymentsContentCells';

export default {
  name: 'PaymentsView',
  components: {
    ConciliationReportModal,
    MuiAlgoliaList,
    PageView,
  },
  inject: ['$labels'],
  data() {
    return {
      isConciliationModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    contentFields() {
      return contentFields();
    },
    facets() {
      return facets(this.operatorTimezone);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.actions = [
      {
        label: this.$labels.Payments.settlement_report,
        method: () => { this.isConciliationModalOpen = true; },
      },
    ];
  },
};
</script>

<template>
  <PageView class="PaymentsView">
    <h2 class="mb-3">
      Payments
    </h2>
    <ConciliationReportModal
      v-if="isConciliationModalOpen"
      :title="$labels.Payments.export_settlement"
      @closeModal="() => isConciliationModalOpen = false"
    />
    <MuiAlgoliaList
      :export-columns="contentFields"
      :facets="facets"
      :filters="getOperatorFilter()"
      :table-config="contentFields"
      :actions="actions"
      :index="ALGOLIA_INDEXES.paymentTransactions"
      is-export-enabled
    />
  </PageView>
</template>
