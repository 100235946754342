var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-form",
    { on: { submitform: _vm.createInvoice } },
    [
      _c(
        "GenericModalComponent",
        _vm._b(
          {
            attrs: { size: _vm.SIZES.small, title: "Create invoice" },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "InvoiceFormComponent",
                        attrs: { "data-test-id": "invoice-modal" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "emobg-body-medium emobg-font-weight-semibold d-flex align-items-center mb-1 px-1",
                                },
                                [_vm._v(" Customer Type* ")]
                              ),
                              _c("ui-radio", {
                                staticClass: "my-2 w-100",
                                attrs: {
                                  value: _vm.isForCompany,
                                  option: false,
                                  required: "",
                                  name: "is_for_company",
                                  caption: "User",
                                  "data-test-id":
                                    "invoice-modal-customer-user-select",
                                },
                                on: {
                                  changevalue: () => (_vm.isForCompany = false),
                                },
                              }),
                              _c("ui-radio", {
                                staticClass: "mb-2 mt-1 w-100",
                                attrs: {
                                  value: _vm.isForCompany,
                                  option: true,
                                  required: "",
                                  name: "is_for_company",
                                  caption: "Company",
                                  "data-test-id":
                                    "invoice-modal-customer-company-select",
                                },
                                on: {
                                  changevalue: () => (_vm.isForCompany = true),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12 mt-3 mb-4" },
                            [
                              _vm.isForCompany
                                ? _c("MuiAlgoliaSelect", {
                                    key: "companySelector",
                                    staticClass: "w-100",
                                    attrs: {
                                      filters: _vm.getOperatorFilter({
                                        attribute: "cs_operator_id",
                                      }),
                                      title: (result) =>
                                        `${result.id} - ${result.commercial_name}`,
                                      index: _vm.ALGOLIA_INDEXES.companies,
                                      label: "Company*",
                                      name: "company",
                                      "no-cache": "",
                                      "path-value": "uuid",
                                      placeholder: "Select company",
                                      "data-test-id":
                                        "invoice-modal-company-autocomplete",
                                    },
                                    model: {
                                      value: _vm.inputs.company_uuid,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputs,
                                          "company_uuid",
                                          $$v
                                        )
                                      },
                                      expression: "inputs.company_uuid",
                                    },
                                  })
                                : _c("MuiAlgoliaSelect", {
                                    key: "userSelector",
                                    staticClass: "w-100",
                                    attrs: {
                                      filters: _vm.getOperatorFilter({
                                        index: _vm.ALGOLIA_INDEXES.users,
                                      }),
                                      title: (result) =>
                                        `${result.first_name} ${result.last_name} - ${result.email}`,
                                      index: _vm.ALGOLIA_INDEXES.users,
                                      label: "User*",
                                      name: "user",
                                      "no-cache": "",
                                      "path-value": "uuid",
                                      placeholder: "Select user",
                                      "data-test-id":
                                        "invoice-modal-user-autocomplete",
                                    },
                                    on: {
                                      selected: (result) =>
                                        _vm.setIdFromEntity("user_fk", result),
                                    },
                                    model: {
                                      value: _vm.inputs.user_uuid,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "user_uuid", $$v)
                                      },
                                      expression: "inputs.user_uuid",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("MuiAlgoliaSelect", {
                                staticClass: "w-100",
                                attrs: {
                                  filters: _vm.filterBookingByCompanyUser,
                                  title: (result) =>
                                    _vm.displayBookingSelector(result),
                                  index: _vm.ALGOLIA_INDEXES.csBookings,
                                  label: "Booking",
                                  name: "cs_booking",
                                  "no-cache": "",
                                  "path-value": "uuid",
                                  placeholder: "Select booking",
                                  "data-test-id":
                                    "invoice-modal-booking-autocomplete",
                                },
                                model: {
                                  value: _vm.inputs.booking_uuid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "booking_uuid", $$v)
                                  },
                                  expression: "inputs.booking_uuid",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex align-content-center" },
                      [
                        _c("CancelButton", {
                          attrs: { "data-test-id": "invoice-modal-cancel" },
                          on: { click: _vm.closeModal },
                        }),
                        _c(
                          "ui-button",
                          {
                            attrs: {
                              disabled: !_vm.areAllRequiredFieldsFilled,
                              loading: _vm.isLoading,
                              type: _vm.BUTTON_TYPES.submit,
                              "data-test-id": "invoice-modal-save-button",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$labels.Common.Actions.save) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "GenericModalComponent",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }