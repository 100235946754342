<script>
import { DATE_FORMAT, formatDate } from '@emobg/web-utils';
import { mapGetters, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import invoicing from '@domains/Invoicing/router/InvoicingRouterMap';

import InvoiceFormComponent from '../partials/InvoiceFormComponent';
import SapExportFormComponent from '../partials/SapExportFormComponent';

import { contentCells, invoiceFacets } from './const/invoicesContentCells';

export default {
  name: 'InvoiceListView',
  components: {
    InvoiceFormComponent,
    MuiAlgoliaList,
    PageView,
    SapExportFormComponent,
  },
  data() {
    return {
      isSapExportModalOpen: false,
      isInvoiceModalOpen: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, {
      invoiceFilters: 'getOperatorFilter',
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),

    exportColumns() {
      return [
        { attributeName: 'id', title: 'Id' },
        { attributeName: 'serie', title: 'Serie' },
        { attributeName: 'number', title: 'Number' },
        { attributeName: 'customer_name', title: 'User/Company' },
        { attributeName: 'operator_city_name', title: 'City' },
        {
          attributeName: 'date_ts',
          title: 'Date',
          transformValue: value => (value ? formatDate(value, DATE_FORMAT.dateTime) : 'X'),
        },
        { attributeName: 'gross_amount_formatted', title: 'Amount' },
        { attributeName: 'pending_amount_formatted', title: 'Pending Amount' },
        { attributeName: 'status', title: 'Invoice Status' },
        {
          attributeName: 'is_pending_to_pay',
          title: 'Payment Status',
          transformValue: value => (value ? 'Unpaid' : 'Paid'),
        },
        {
          attributeName: 'sent',
          title: 'Sent',
          transformValue: value => (value ? 'Yes' : 'No'),
        },
        { attributeName: 'cs_booking_fk', title: 'Booking ID' },
      ];
    },
    invoiceFacets() {
      return invoiceFacets(this.operatorTimezone);
    },
    contentCells() {
      return contentCells();
    },
  },

  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;

    this.actions = [
      { label: 'SAP Export', method: () => { this.$router.push({ name: invoicing.invoices.sapFiles }); } },
      { label: 'Create Invoice', method: () => { this.isInvoiceModalOpen = true; }, class: 'emobg-color-primary' },
    ];
  },
};
</script>

<template>
  <PageView class="InvoiceListView">
    <h1 class="mb-3">
      Invoices
    </h1>
    <MuiAlgoliaList
      :table-config="contentCells"
      :filters="invoiceFilters()"
      :facets="invoiceFacets"
      :export-columns="exportColumns"
      :actions="actions"
      :index="ALGOLIA_INDEXES.invoices"
      is-export-enabled
      data-test-id="invoice-list"
    />
    <SapExportFormComponent
      v-if="isSapExportModalOpen"
      title="SAP Export"
      @closeModal="isSapExportModalOpen = false"
    />
    <InvoiceFormComponent
      v-if="isInvoiceModalOpen"
      title="Create invoice"
      @closeModal="isInvoiceModalOpen = false"
    />
  </PageView>
</template>
