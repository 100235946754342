<script>
import get from 'lodash/get';
import map from 'lodash/map';
import { mapActions, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import { NOTIFICATION_TYPES } from '@/constants/notifications';

export default {
  name: 'ConciliationReportModal',
  components: {
    GenericModalComponent,
    CancelButton,
  },
  inject: ['$labels'],
  data() {
    return {
      inputs: {
        report: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: userState => userState.operators.active,
    }),
    ...mapState(DOMAINS_MODEL.invoicing.payments, {
      reports: state => state.conciliationReports.data,
      isReportsLoaded: state => state.conciliationReports.STATUS.LOADED,
      isReportMediaError: state => state.conciliationReportMedia.STATUS.ERROR,
      isReportMediaLoading: state => state.conciliationReportMedia.STATUS.LOADING,
      isReportMediaLoaded: state => state.conciliationReportMedia.STATUS.LOADED,
      reportMediaUrl: state => state.conciliationReportMedia.data.url,
    }),
    reportsOptions() {
      return map(this.reports, report => ({
        value: report.uuid,
        label: `${get(report, 'startMonth')} — ${get(report, 'endMonth')}`,
      }));
    },
  },
  mounted() {
    this.getConciliationReports(this.activeOperator.uuid);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.payments, [
      'getConciliationReports',
      'getConciliationReport',
    ]),
    async exportReport() {
      await this.getConciliationReport(this.inputs.report);
      if (this.isReportMediaLoaded) {
        const link = document.createElement('a');
        link.dataset.testId = 'settlement-download-link';
        link.href = this.reportMediaUrl;
        link.download = 'settlementReport';
        link.click();
        this.closeModal();
      } else {
        this.$notify({
          message: 'Error fetching settlement',
          textAction: 'OK',
          type: NOTIFICATION_TYPES.error,
        });
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
<template>
  <GenericModalComponent
    v-bind="$attrs"
    :size="SIZES.small"
    is-open
  >
    <template #body>
      <div class="row p-5">
        <div class="col-12">
          <ui-select
            v-if="isReportsLoaded"
            :value="inputs.report"
            :options.prop="reportsOptions"
            :label="$labels.Payments.select_report"
            :searchbox.prop="{ threshold: -1 }"
            name="reportSelector"
            class="w-100 mb-3"
            data-test-id="conciliation-modal-selector"
            @selectoption="({ detail }) => inputs.report = detail"
          />
          <ui-skeleton
            v-else
            height="40"
          />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="d-flex align-content-center">
        <CancelButton
          :disabled="isReportMediaLoading"
          data-test-id="conciliation-modal-cancel-button"
          @click="closeModal"
        />
        <ui-button
          :disabled="!inputs.report"
          :loading="isReportMediaLoading"
          data-test-id="conciliation-modal-export-button"
          @clickbutton="exportReport"
        >
          {{ $labels.Common.Actions.export }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
