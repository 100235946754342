<script>
import $labels from '@domains/Invoicing/const/labels';

export default {
  name: 'InvoicingView',

  provide: { $labels },
};
</script>

<template>
  <div class="InvoicingView">
    <RouterView />
  </div>
</template>
