<script>
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { MuiAlgoliaList } from '@emobg/motion-ui';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

import { getFacets, getFields } from './config/PayoutsAlgoliaTable';

export default {
  name: 'PayoutsView',
  components: {
    MuiAlgoliaList,
    PageView,
  },
  props: {
    isCompany: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperator: state => state.operators.active,
    }),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.algolia = {
      fields: getFields(),
      facets: getFacets(this.activeOperator.timezone),
    };
  },
};
</script>

<template>
  <PageView class="PayoutsView">
    <ui-card
      header="Payouts"
      description="Payouts for the current operator"
    >
      <MuiAlgoliaList
        :table-config="algolia.fields"
        :export-columns="algolia.fields"
        :facets="algolia.facets"
        :filters="`cs_operator.uuid:${activeOperator.uuid}`"
        :index="ALGOLIA_INDEXES.payoutTransactions"
        is-export-enabled
      />
    </ui-card>
  </PageView>
</template>
