var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "PaymentsView" },
    [
      _c("h2", { staticClass: "mb-3" }, [_vm._v(" Payments ")]),
      _vm.isConciliationModalOpen
        ? _c("ConciliationReportModal", {
            attrs: { title: _vm.$labels.Payments.export_settlement },
            on: { closeModal: () => (_vm.isConciliationModalOpen = false) },
          })
        : _vm._e(),
      _c("MuiAlgoliaList", {
        attrs: {
          "export-columns": _vm.contentFields,
          facets: _vm.facets,
          filters: _vm.getOperatorFilter(),
          "table-config": _vm.contentFields,
          actions: _vm.actions,
          index: _vm.ALGOLIA_INDEXES.paymentTransactions,
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }